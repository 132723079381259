<template>
  <header class="page__header">
    <section class="header__container">
      <div class="header__left">
        <button class="header__menu-button" @click="handleVisibleMenu()">
          <el-icon v-if="props.openStatus"><Fold /></el-icon>
          <el-icon v-else><Expand /></el-icon>
        </button>
        <nav class="header__nav">
          <ul class="nav__box">
            <li v-if="$route.meta.menu">
              <NuxtLink v-if="$route.meta.menuPath" :to="$route.meta.menuPath">{{ $route.meta.menu }}</NuxtLink>
              <slot v-else>{{ $route.meta.menu }}</slot>
            </li>
            <slot v-if="$route.params.roomId && groupStore.group_title">
              <li style="color: #ababab">></li>
              <li>
                <NuxtLink :to="{ name: 'room-roomId', params: { roomId: $route.params.roomId } }">{{ groupStore.group_title }}</NuxtLink>
              </li>
            </slot>
            <slot v-if="$route.meta.menu2">
              <li style="color: #ababab">></li>
              <li>{{ $route.meta.menu2 }}</li>
            </slot>
          </ul>
        </nav>
      </div>
    </section>
  </header>
  <el-drawer
    v-model="props.openStatus"
    :with-header="false"
    direction="ltr"
    class="tablet-menu"
    modal-class="tablet-menu-modal"
    :before-close="handleCloseDrawer"
  >
    <div class="aside-wrap aside-wrap--tablet">
      <div class="aside-wrap__title">
        <img src="@/assets/img/logo.svg" @click="handlePushRouter('index')">
        <NuxtLink v-if="authStore.capability.value === 'starter'" :to="{ name: 'contact' }">{{ getLicenseTxt() }}</NuxtLink>
      </div>
      <!--
      <div class="aside-wrap__membership">
        <button class="btn" @click="handleVisibleMembershipCode()">멤버십 코드</button>
      </div>
      -->
      <div class="aside-wrap__menu">
        <el-menu :default-active="$route.name">
          <el-menu-item index="room">
            <NuxtLink :to="{ name: 'room' }" @click="handleVisibleMenu()">내 그룹</NuxtLink>
          </el-menu-item>
          <el-sub-menu v-if="$route.params && $route.params.roomId" index="2">
            <template #title><div>{{ groupStore.group_title || '그룹' }}</div></template>
            <el-menu-item index="room-roomId">
              <NuxtLink :to="{ name: 'room-roomId', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">그룹 홈</NuxtLink>
            </el-menu-item>
            <slot v-if="groupStore.isOwner || authStore.isAdmin">
              <el-menu-item index="room-roomId-activity">
                <NuxtLink :to="{ name: 'room-roomId-activity', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">활동 및 글감 관리</NuxtLink>
              </el-menu-item>
              <el-menu-item index="room-roomId-member">
                <NuxtLink :to="{ name: 'room-roomId-member', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">참여자 관리</NuxtLink>
              </el-menu-item>
              <el-menu-item index="room-roomId-analytics">
                <NuxtLink :to="{ name: 'room-roomId-analytics', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">그룹 통계</NuxtLink>
              </el-menu-item>
              <el-menu-item index="room-roomId-form">
                <NuxtLink :to="{ name: 'room-roomId-form', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">그룹 설정</NuxtLink>
              </el-menu-item>
            </slot>
            <slot v-else-if="groupStore.isMember">
              <el-menu-item index="room-roomId-analytics">
                <NuxtLink :to="{ name: 'room-roomId-analytics', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">그룹 통계</NuxtLink>
              </el-menu-item>
              <el-menu-item index="2-6">
                <a href="javascript:void(0)" @click="handleWithdrawGruop()">그룹 탈퇴</a>
              </el-menu-item>
            </slot>
          </el-sub-menu>
          <el-menu-item index="room-square">
            <NuxtLink :to="{ name: 'room-square' }" @click="handleVisibleMenu()">그룹 광장</NuxtLink>
          </el-menu-item>
          <el-menu-item index="room-my-posts">
            <NuxtLink :to="{ name: 'room-my-posts' }" @click="handleVisibleMenu()">내 글 모아보기</NuxtLink>
          </el-menu-item>
          <el-sub-menu index="4">
            <template #title>디지털 책장</template>
            <el-menu-item index="4-1">
              <a href="https://book.jajakjajak.com/digital-books" target="_blank">
                내 책장
                <ClientOnly>
                  <font-awesome-icon :icon="faArrowUpRightFromSquare" style="font-size: 0.85em; margin-left: 5px; vertical-align: middle;" />
                </ClientOnly>
              </a>
            </el-menu-item>
            <el-menu-item index="4-2">
              <a href="https://book.jajakjajak.com/digital-book-making" target="_blank">
                디지털 책 만들기
                <ClientOnly>
                  <font-awesome-icon :icon="faArrowUpRightFromSquare" style="font-size: 0.85em; margin-left: 5px; vertical-align: middle;" />
                </ClientOnly>
              </a>
            </el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="5">
            <template #title>고객센터</template>
            <el-menu-item index="5-1">
              <a href="tel:07079314525" @click="handleVisibleMenu()">고객센터 연결</a>
            </el-menu-item>
            <el-menu-item index="5-4">
              <a href="javascript:void(0)" @click="handleVisibleTerms()">서비스 이용약관</a>
            </el-menu-item>
            <el-menu-item index="5-5">
              <a href="javascript:void(0)" @click="handleVisiblePrivacyTerms()">개인정보 처리방침</a>
            </el-menu-item>
          </el-sub-menu>
          <slot v-if="authStore.isLogin">
            <el-sub-menu index="7">
              <template #title>마이페이지</template>
              <el-menu-item index="mypage-profile">
                <NuxtLink :to="{ name: 'mypage-profile' }">계정 프로필</NuxtLink>
              </el-menu-item>
              <el-menu-item index="mypage-security">
                <NuxtLink :to="{ name: 'mypage-security' }">암호변경</NuxtLink>
              </el-menu-item>
              <el-menu-item index="7-4">
                <a href="javascript:void(0)" @click="handleLogout()">로그아웃</a>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu v-if="authStore.isLicenseOwner" index="settings">
              <template #title>관리</template>
                <el-menu-item index="settings-licenseUser">
                  <NuxtLink :to="{ name: 'settings-licenseUser' }" @click="handleVisibleMenu()">라이센스 멤버 관리</NuxtLink>
                </el-menu-item>
            </el-sub-menu>
          </slot>
          <el-menu-item index="contact">
            <NuxtLink :to="{ name: 'contact' }">가격안내</NuxtLink>
          </el-menu-item>
          <el-menu-item index="9">
            <a href="https://teampl100.notion.site/ae318e93c65a48fda2055d202b19ab8f" target="_blank">
              제품안내
              <ClientOnly>
                <font-awesome-icon :icon="faArrowUpRightFromSquare" style="font-size: 0.85em; margin-left: 5px; vertical-align: middle;" />
              </ClientOnly>
            </a>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </el-drawer>
  <el-dialog v-model="membershipCodeVisible" title="멤버십 코드 입력" width="420px" append-to-body center>
    <MembershipCode v-if="membershipCodeVisible" @close="handleVisibleMembershipCode()" />
  </el-dialog>
  <el-dialog v-model="termsVisible" width="900px">
    <Terms />
  </el-dialog>
  <el-dialog v-model="privacyTermsVisible" width="900px">
    <TermsPrivacy />
  </el-dialog>
</template>

<script setup lang="ts">
import { Expand, Fold } from '@element-plus/icons-vue';
import { deleteMember } from "~/composables/php/group";
import { userLogout } from '@/composables/php/auth';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

const props = defineProps({
  openStatus: {
    required: true,
    default: true,
  },
})

const emit = defineEmits(['changeOpenStatus'])

const route = useRoute();

const groupStore = useGroupStore();

const authStore = useAuthStore();

const membershipCodeVisible = ref(false);

const termsVisible = ref(false);

const privacyTermsVisible = ref(false);

// const accessToken = useJajakToken();

function getLicenseTxt() {
  return getLicense(authStore.user_type, authStore.capability);
}

function handleVisibleMembershipCode() {
  membershipCodeVisible.value = !membershipCodeVisible.value;
}

async function handleLogout() {
  await userLogout().then(() => {
    authStore.resetUser();
    useRouter().push({ name : 'index' });
  }).catch((e) => {
    const message = e.response && e.response.data && e.response.data.message || e.message;
      ElMessage.error(message || '알 수 없는 오류가 발생하였습니다.')
  });
}

function groupWithdrawValidator(val: any) {
  if (val.trim() === groupStore.group_title.trim()) {
    return true;
  }
  return false;
}

function handleWithdrawGruop() {
  ElMessageBox.prompt(
    h('div', null, [
      h('p', null, '정말로 그룹에서 탈퇴하시겠습니까?'),
      h('p', null, '탈퇴하려면 아래 입력 창에 그룹 이름을 입력해 주세요.'),
    ]),
    '그룹탈퇴',
    {
      confirmButtonText: '네, 탈퇴할래요.',
      cancelButtonText: '아니오.',
      inputErrorMessage: `탈퇴하는 그룹명을 정확히 입력해 주세요.`,
      inputValidator: groupWithdrawValidator,
    },
  ).then(() => {
    deleteMember(route.params.roomId, authStore.user_login).then((res) => {
      ElMessage.success('그룹에서 탈퇴처리 되었습니다.');
      useRouter().push({ name: 'room' });
    }).catch((e) => {
      const message = e.response && e.response.data && e.response.data.message || e.message;
      ElMessage.error(message || '알 수 없는 오류가 발생하였습니다.')
    });
  });
}


function handlePushRouter(name: string) {
  useRouter().push({ name });
  handleVisibleMenu();
}

function handleVisibleTerms() {
  termsVisible.value = !termsVisible.value;
}

function handleVisiblePrivacyTerms() {
  privacyTermsVisible.value = !privacyTermsVisible.value;
}

function handleVisibleMenu() {
  emit('changeOpenStatus');
}

function handleCloseDrawer(done: any) {
  emit('changeOpenStatus');
  done();
}
</script>

<style lang="scss">
@import '@/assets/css/layouts/common/header.scss';
</style>
